<template>
  <div class="wa__container">
    <header class="pt-3">
      <div class="pt-3">
        <v-row>
          <v-col cols="12">
            <site-header
              backRouteName="portfolio"
              backText="portfolios"
              :title="`${form.id ? 'update' : 'add a'} portfolio`"
              sub-title="write down the event"
            ></site-header>
          </v-col>
        </v-row>
      </div>
    </header>
    <v-container>
      <main class="pb-9 pt-4">
        <div>
          <list title="basic content" class="h-100 wa__large">
            <loading v-if="loadingGetData" class="loading-data" />
            <v-form
              @submit.prevent="addPortfolio"
              :class="{ 'loading-form': loadingGetData }"
              ref="portfolioForm"
            >
              <hr class="mt-0" />
              <v-row>
                <v-col cols="12" sm="6" class="mt-1">
                  <v-text-field
                    label="title"
                    color="SonicSilver"
                    class="mt-0 pt-0"
                    dense
                    outlined
                    :rules="requiredRules"
                    v-model="form.title"
                  ></v-text-field>
                  <v-textarea
                    color="SonicSilver"
                    rows="4"
                    label="summary"
                    dense
                    outlined
                    :rules="requiredRules"
                    v-model="form.summary"
                  ></v-textarea>
                  <v-textarea
                    color="SonicSilver"
                    rows="3"
                    label="META TAG DESCRIPTION"
                    dense
                    outlined
                    v-model="form.meta_description"
                  ></v-textarea>
                  <v-text-field
                    label="video link"
                    color="SonicSilver"
                    class="no-error-msg"
                    outlined
                    dense
                    v-model="form.video_link"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="pt-0">
                  <div class="h-100">
                    <div class="add__program w-100">
                      <image-cropper
                        :stencilProps="{
                          aspectRatio: 1280 / 720,
                          checkImageOrigin: false,
                        }"
                        v-model="fileForm"
                        :url.sync="form.thumbnail"
                        label="portfolio banner"
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="3">
                  <AutocompleteProject label="related project" v-model="form.project" />
                </v-col>
                <v-col cols="12" sm="3">
                  <AutocompleteClient
                    label="Contractor - client"
                    outlined
                    dense
                    v-model="form.client"
                  />
                </v-col>
                <v-col cols="12" sm="3">
                  <date-input
                    label="project date"
                    color="SonicSilver"
                    outlined
                    dense
                    v-model="form.project_date"
                  ></date-input>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field
                    label="project location"
                    color="SonicSilver"
                    outlined
                    dense
                    :rules="requiredRules"
                    v-model="form.location"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col>
                  <AutocompleteModel
                    v-if="!loadingGetData"
                    color="SonicSilver"
                    v-model="form.users"
                    label="models included"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col>
                  <v-combobox
                    clearable
                    :append-icon="null"
                    hide-selected
                    label="tags"
                    outlined
                    color="SonicSilver"
                    dense
                    multiple
                    persistent-hint
                    small-chips
                    v-model="form.tags"
                  >
                    <template #selection="{ item }">
                      <v-chip
                        close
                        @click:close="removeTags(item)"
                        class="mt-1 chip__tag"
                        text-color="black"
                        label
                        small
                      >
                        {{ item }}
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
              <v-row class="mb-4">
                <v-col cols="12">
                  <div class="upload">
                    <div class="add__portfolio__dropzone w-100 h-100">
                      <Dropzone
                        :batch-id="form.file_batch_id"
                        model-name="portfolio"
                        defaultMessage="
                    <div class='d-flex align-center'><i class='WMi-image-svgrepo-com dropzone-icon'></i><div class='text-left'><div class='dropzone-title text-uppercase' style='letter-spacing: 0;'>Drop portfolio images here</div>
                    <div class='dropzone-sub-title'>
                      Allowed Formats:JPG,JPEG,PNG | Max FileSize: 20MB 
                    </div>
                    </div>
                    </div>
                    "
                      />
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-row class="pb-10">
                <v-col cols="12" sm="6" class="pt-1 text-center text-sm-left">
                  <router-link
                    :to="{
                      name: 'portfolio',
                      params: { locale: $_getlocaleParam() },
                    }"
                    class="text-decoration-none"
                  >
                    <block-button
                      height="30"
                      class="btn__size__14 cancel__button br__mode"
                      text="cancel"
                      color="black"
                    ></block-button>
                  </router-link>
                </v-col>
                <v-col cols="12" sm="6" class="text-right pt-1">
                  <block-button
                    text="save"
                    height="30"
                    :loading="loadingSendData"
                    class="btn__size__18 site__button width-200 add__mode float-right"
                    type="submit"
                  ></block-button
                ></v-col>
              </v-row>
            </v-form>
          </list>
        </div>
      </main>
    </v-container>
    <footer>
      <div class="wa__home--footer">
        <site-footer text="All Right Reserved"></site-footer>
      </div>
    </footer>
  </div>
</template>
<script>
import {requiredRules} from "@/mixins/validate"
import AutocompleteModel from "@/components/Global/Input/AutocompleteModel.vue";
import ImageCropper from "@/components/Global/Input/ImageCropper";
import Dropzone from "@/components/Global/Input/Dropzone";
import PortfolioRepository from "@/abstraction/repository/portfolio/portfolioRepository";
const portfolioRepository = new PortfolioRepository();
import AutocompleteClient from "@/components/Global/Input/AutocompleteClient.vue";
import AutocompleteProject from "@/components/Global/Input/AutocompleteProject.vue";
import FileRepository from "@/abstraction/repository/FileRepository";
const fileRepository = new FileRepository();
import { mapActions } from "vuex";
import { makeid } from "@/utils/math";
const RANDOM_TOKEN = makeid(50);
export default {
  name: "add_portfolio",
  components: {
    ImageCropper,
    Dropzone,
    AutocompleteModel,
    AutocompleteClient,
    AutocompleteProject,
  },
  data() {
    return {
      form: {
        file_batch_id: RANDOM_TOKEN,
        thumbnail: "https://via.placeholder.com/1280x720",
      },
      loadingGetData: false,
      fileForm: {
        model_name: "portfolio",
        collection_name: "main_image",
        batch_id: RANDOM_TOKEN,
      },
      loadingSendData: false,
      portfolioId: this.$route.params.id,
    };
  },
  methods: {
    ...mapActions("portfolio", ["updatePortfolio", "storePortfolio"]),
    removeTags(item) {
      this.form.tags.splice(this.form.tags.indexOf(item), 1);
      this.form.tags = [...this.form.tags];
    },
    async addPortfolio() {
      try {
        if (!this.$refs.portfolioForm.validate()) {
          return;
        }
        this.loadingSendData = true;
        if (this.fileForm.file) {
          await fileRepository.store(this.fileForm);
        }

        let response;
        if (this.form.id) {
          response = await this.updatePortfolio(this.form);
        } else {
          response = await this.storePortfolio(this.form);
        }
        if (!(response instanceof Error)) {
          this.$router.push({
            name: "portfolio",
            params: {
              locale: this.$_getlocaleParam(),
            },
          });
        }
      } catch (e) {
        return e;
      } finally {
        this.loadingSendData = false;
      }
    },
    async loadPortfolioById() {
      if (this.portfolioId) {
        try {
          this.loadingGetData = true;
          const response = await portfolioRepository.show(this.portfolioId);
          this.form = { ...this.form, ...response };
        } catch (error) {
          console.error(error);
          return error;
        } finally {
          this.loadingGetData = false;
        }
      }
    },
  },
  computed: {
    requiredRules
  },
  created() {
    this.loadPortfolioById();
  },
  mounted() {
    this.showEditor = true;
  },
};
</script>
<style scoped>
/* container */
.container {
  max-width: 1640px;
}
.upload {
  border: 1px dotted var(--color-gray) !important;
  border-radius: 7px;
  position: relative;
}

.loading-data {
  position: absolute;
  top: 30%;
  left: 50%;
  z-index: 9999;
  transform: translate(-50%, -50%);
}

.loading-form {
  filter: blur(5px);
}

@media screen and (max-width: 1264px) {
  .loading-data {
    position: absolute;
    left: 50%;
    z-index: 9999;
    transform: translateX(-50%);
    top: 50px;
  }
}

@media screen and (max-width: 960px) {
  .loading-data {
    position: absolute;
    left: 50%;
    z-index: 9999;
    transform: translateX(-50%);
    top: 50px;
  }
}

@media screen and (max-width: 600px) {
  .loading-data {
    position: absolute;
    left: 50%;
    z-index: 9999;
    transform: translateX(-50%);
    top: 50px;
  }
}
</style>
<style lang="scss">
.chip__tag {
  font-family: "montserrat-regular", sans-serif !important;
  font-size: 12px !important;
}
</style>
