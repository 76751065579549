<template>
  <v-autocomplete
    :items="projects"
    color="SonicSilver"
    v-model="project"
    item-text="name"
    :label="label"
    append-icon="WMi-search"
    @keyup="filterProjects"
    :loading="isLoading"
    return-object
    dense
    outlined
    hide-no-data
    no-filter
  >
    <template>
      <v-row class="border-bottom border-light-gray">
        <v-col
          xl="9"
          md="9"
          class="pa-1 flex-justified-right"
          v-for="project in projects"
          :key="project.id"
        >
          <Name :title="project.name" :sub-title="project.cell_phone" />
        </v-col>
      </v-row>
    </template>
  </v-autocomplete>
</template>

<script>
import debouncedFunction from "@/utils/filter/autocompleteFilter.js";
import Name from "@/components/Global/Section/Name.vue";
import ProjectRepository from "@/abstraction/repository/projectRepository";
const repository = new ProjectRepository();
export default {
  components: {
    Name,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    label: {
      type: String,
      default: () => ("search project"),
    },
  },
  data() {
    return { projects: [], project: this.value, isLoading: false };
  },
  methods: {
    filterProjects(event) {
      debouncedFunction(event, async () => {
        let filters = {
          name: {
            type: "like",
            val: event.target.value,
          },
        };
        let params = {
          filters,
          pagination: {
            page: 1,
          },
        };
        try {
          const response = await repository.index(params);
          this.projects = response.data;
        } catch (err) {
          console.log(err, "there was an error loading projects");
        } finally {
          this.isLoading = false;
        }
      });
    },
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.project = this.value;
      },
    },
    project: {
      deep: true,
      immediate: true,
      async handler() {
        if (
          this.project &&
          this.project.id &&
          !this.projects.find((c) => c.id === this.project.id)
        ) {
          this.projects.push(this.project);
        }

        this.$emit("input", this.project);
      },
    },
  },
};
</script>
