<template>
  <div>
    <vue-dropzone
      ref="myVueDropzone"
      id="dropzone"
      :destroyDropzone="false"
      @vdropzone-sending="setParams"
      @vdropzone-removed-file="removeFile"
      :options="dropzoneOptions"
      @vdropzone-success="responseSuccess"
    >
    </vue-dropzone>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import { TokenStorage } from "@/utils/storage";
import url from "@/router/url";
import axios from "axios";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  props: {
    defaultMessage: {
      default:
        "<div class='d-flex align-center'><i class='WMi-image-svgrepo-com dropzone-icon'></i><div class='text-left'><div class='dropzone-title'>Drop your images here</div> <div class='dropzone-sub-title'> Allowed Formats:JPG,JPEG,PNG | Max FileSize: 20MB  </div></div></div>",
    },
    maxFiles: {
      type: Number,
      default: null,
    },
    files: { default: () => [] },
    collection: { default: "gallery" },
    modelName: { default: "model" },
    modelId: { default: null },
    batchId: {
      type: String,
      default: "asdfghjklqwertyuiopmnbvcxasdfghjklqwertyuiopmnbvcx",
    },
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      dropzoneOptions: {
        acceptedFiles:'image/*',
        maxFilesize:19.90,
        url: this.$baseUrl + url("storeFile"),
        thumbnailWidth: 200,
        headers: { Authorization: `${TokenStorage.getToken()}` },
        addRemoveLinks: true,
        dictDefaultMessage: this.defaultMessage,
        sendingMultiple: true,
        removeType: "server",
        maxFiles: this.maxFiles,
      },
    };
  },
  methods: {
    responseSuccess(file, response) {
      if (response.data && response.data.id) {
        file["id"] = response.data.id;
        this.$emit("success", response.data);
      }
    },
    removeFile(file) {
      if (this.dropzoneOptions.removeType == "server") {
        axios.delete(url("destroyFile", { file: file.id }));
      }
      this.$emit("remove", file.id);
    },
    manuallyLoadFiles(files) {
      //file =[ {size: 123, name: "Icon", type: "image/png", url: "https://myvizo.com/img/logo_sm.png"}]
      if (Array.isArray(files) && files.length) {
        for (const file of files) {
          this.$refs.myVueDropzone.manuallyAddFile(
            {
              name: file.file_name,
              type: file.mime_type,
              id: file.id,
              size: file.size,
            },
            file.thumbnail
          );
        }
      }
    },
    manuallyRemoveAllFiles() {
      this.dropzoneOptions.removeType = "client";
      this.$refs.myVueDropzone.removeAllFiles();
      this.dropzoneOptions.removeType = "server";
    },
    setParams(file, xhr, formData) {
      formData.append("batch_id", this.batchId);
      formData.append("model_name", this.modelName);
      formData.append("collection_name", this.collection);
      if (this.modelId) {
        formData.append("model_id", this.modelId);
      }
    },
  },
  watch: {
    files(files) {
      this.manuallyRemoveAllFiles();
      this.manuallyLoadFiles(files);
    },
  },
};
</script>
<style>
.vue-dropzone {
  border: 1px solid #bdbdbd;
  background: #eeeeee;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropzone-title {
  font-family: "Montserrat";
  font-size: 20px;
  color: black;
  letter-spacing: 11px;
  text-transform: uppercase;
}
.dropzone-icon {
  font-size: 53px;
  color: var(--color-black);
}
.dropzone-sub-title {
  font-family: "Montserrat-regular";
  letter-spacing: 1px;
  font-size: 18px;
}
@media screen and (max-width: 960px) {
  .dropzone-title {
    font-size: 19px;
    letter-spacing: 9px;
  }
  .dropzone-sub-title {
    font-size: 17px;
  }
}
@media screen and (max-width: 600px) {
  .dropzone-title {
    font-size: 16px;
    letter-spacing: 5px;
  }
  .dropzone-sub-title {
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .dropzone-title {
    font-size: 13px;
    letter-spacing: 2px;
  }
  .dropzone-sub-title {
    font-size: 10px;
  }
}
</style>
